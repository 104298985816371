import edit from "./assets/icons/edit.png";
import { useContext, useEffect, useState } from "react";
import { useAccount, useSigner } from "wagmi";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import registryAbi from "./contracts/registry_abi.json";
import registrarAbi from "./contracts/registrar_abi.json";
import NewSubdomainModal from "./components/UI/NewSubdomain";
import EditOwnerInfoAll from "./components/UI/EditOwnerInfo";
import TransferDomain from "./components/UI/TransferDomain";
import { AppContext } from "./context/appContext";
import UserSubDomainlistItem from "./components/UI/userSubdomainListItem";
import ErrorPage from "./components/UI/UserErrorPage";
import LocalLoading from "./components/UI/LocalLoading";
import empty from "./assets/icons/empty.png";
import { errorToast, successToast } from "./components/UI/ToastAlerts";

const User = () => {
  const { address, isConnected } = useAccount();

  const [primaryDomain, setPrimaryDomain] = useState("");
  const [isDomainOwner, setDomainOwner] = useState(true);
  const [newSubModal, setNewSubModal] = useState(false);
  const [subdomainList, setSubdomainList] = useState([]);
  const [displayDomain, setDisplayDomain] = useState();
  const [userDomains, setUserDomains] = useState([]);
  const [userDomainsAmount, setUserDomainsAmount] = useState(0);
  const [registrarAdd, setRegistrarAdd] = useState("");
  const [ownerCondition, setOwnerCondition] = useState("all");

  const [allInfoOwnerModal, setAllInfoOwnerModal] = useState(false);
  const [domainImage, setdomainImage] = useState("");
  const [domainDesc, setdomainDesc] = useState("");
  const [domainWebs, setdomainWebs] = useState("");
  const [domainEmail, setdomainEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [transferDomainVis, settransferDomainVis] = useState(false);

  const { data: signer } = useSigner();

  const { registryAdd, staticProvider, setGlobalLoading, setLoadingMessage } =
    useContext(AppContext);

  const readRegistryContract = new ethers.Contract(
    registryAdd,
    registryAbi,
    staticProvider
  );

  const fetchInitialData = async () => {
    setGlobalLoading(true);
    setLoadingMessage("Loading User Data");

    try {
      const balance = await readRegistryContract.balanceOf(address);
      const formBal = ethers.utils.formatUnits(balance, 0);

      if (formBal === "0") {
        setDomainOwner(false);
        return setGlobalLoading(false);
      }

      const primDom = await readRegistryContract.primaryDomain(address);
      const formatedBalance = ethers.utils.formatUnits(balance, 0);

      const userDomainsFetch = [];
      for (let i = 0; i < formatedBalance; i++) {
        const token = await readRegistryContract.tokenOfOwnerByIndex(
          address,
          i
        );
        const data = await readRegistryContract.tokenToDomain(token);
        userDomainsFetch.push(ethers.utils.parseBytes32String(data));
      }

      setPrimaryDomain(ethers.utils.parseBytes32String(primDom));
      setDisplayDomain(ethers.utils.parseBytes32String(primDom));

      setUserDomains(userDomainsFetch);
      setUserDomainsAmount(formatedBalance);

      const registryContract = new ethers.Contract(
        registryAdd,
        registryAbi,
        staticProvider
      );

      const info = await registryContract.registry(primDom);
      setRegistrarAdd(info.registrar);

      const readRegistrarContract = new ethers.Contract(
        info.registrar,
        registrarAbi,
        staticProvider
      );

      console.log(info.registrar);

      const ownerData = await readRegistrarContract.ownerInfo();

      setdomainImage(ownerData.avatar);
      setdomainDesc(ownerData.description);
      setdomainWebs(ownerData.website);
      setdomainEmail(ownerData.email);

      try {
        // const allSubdomains = await readRegistrarContract.getAllSubdomains();
        // console.log(allSubdomains);
        // const formated = allSubdomains.map((item) =>
        //   ethers.utils.parseBytes32String(item)
        // );
        // console.log(allSubdomains, "fetched");
        // setSubdomainList(formated);
        setGlobalLoading(false);
      } catch (error) {
        console.log(error, "fetched");
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log(error);
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    fetchInitialData();
  }, [address, isConnected]);

  const changePrimary = async (e) => {
    setLoadingMessage("Changing Primary Domain");
    setGlobalLoading(true);

    const registryContract = new ethers.Contract(
      registryAdd,
      registryAbi,
      signer
    );

    const parsedText = ethers.utils.formatBytes32String(e.target.textContent);
    try {
      const changePrim = await registryContract.setPrimaryDomain(parsedText);
      await changePrim.wait();

      setGlobalLoading(false);
      fetchInitialData();
      successToast("Primary Domain Changed!");
    } catch (error) {
      console.log(error);
      setGlobalLoading(false);
      errorToast("Couldn't Change Primary Domain");
    }
  };

  const changeDisplayDomain = async (e) => {
    setLoadingMessage("Changing Display Domain");
    setGlobalLoading(true);
    console.log("test 1");

    setDisplayDomain(e.target.textContent);
    await fetchDomainData(e.target.textContent);

    setGlobalLoading(false);
    console.log("test 2");
  };

  const fetchDomainData = async (domain) => {
    const registryContract = new ethers.Contract(
      registryAdd,
      registryAbi,
      signer
    );

    const parsedText = ethers.utils.formatBytes32String(domain);
    const info = await registryContract.registry(parsedText);

    setRegistrarAdd(info.registrar);

    const readRegistrarContract = new ethers.Contract(
      info.registrar,
      registrarAbi,
      staticProvider
    );

    const allSubdomains = await readRegistrarContract.getAllSubdomains();

    const formated = allSubdomains.map((item) =>
      ethers.utils.parseBytes32String(item)
    );

    setSubdomainList(formated);

    // General data
    const ownerData = await readRegistrarContract.ownerInfo();

    setdomainImage(ownerData.avatar);
    setdomainDesc(ownerData.description);
    setdomainWebs(ownerData.website);
    setdomainEmail(ownerData.email);
  };

  const hadnleEditWebsite = () => {
    setOwnerCondition("website");
    setAllInfoOwnerModal(true);
  };

  const hadnleEditAvatar = () => {
    setOwnerCondition("avatar");
    setAllInfoOwnerModal(true);
  };

  const hadnleEditDescript = () => {
    setOwnerCondition("description");
    setAllInfoOwnerModal(true);
  };

  const hadnleEditEmail = () => {
    setOwnerCondition("email");
    setAllInfoOwnerModal(true);
  };

  const handleChangeAll = () => {
    setOwnerCondition("all");
    setAllInfoOwnerModal(true);
  };

  const handleDomainTransfer = async () => {
    settransferDomainVis(true);
  };

  return (
    <section className="w-full flex justify-center">
      {(!isDomainOwner || !isConnected) && <ErrorPage />}

      {newSubModal && (
        <NewSubdomainModal
          signer={signer}
          registrarAdd={registrarAdd}
          setVisibility={setNewSubModal}
          refresher={fetchInitialData}
        />
      )}

      {/* {globalLoading && <Loading />} */}

      {allInfoOwnerModal && (
        <EditOwnerInfoAll
          signer={signer}
          registrarAdd={registrarAdd}
          setVisibility={setAllInfoOwnerModal}
          domain={primaryDomain}
          condition={ownerCondition}
          refresher={fetchDomainData}
          displayDom={displayDomain}
        />
      )}

      {transferDomainVis && (
        <TransferDomain
          signer={signer}
          setVisibility={settransferDomainVis}
          address={address}
          domains={userDomains}
          balance={userDomainsAmount}
        />
      )}

      <div className="max-w-screen-2xl gap-2 px-2 flex p-1 sm:px-4 flex-col items-center justify-center min-h-screen w-full">
        {/* Top row */}
        <div className="flex mt-20 flex-wrap w-full justify-center gap-1">
          {/* Avatar */}
          <div className="relative w-60 h-60 md:w-44 md:h-44 rounded-sm flex justify-center items-center hover:border-[#3c4e60] bg-[#222222] border-2 border-[#212c37]">
            <img
              src={domainImage}
              alt=""
              className="rounded-sm w-full h-full"
            />
            <button
              className="absolute bottom-2 right-2 p-[1px] rounded-sm bg-[#e8e8e8]"
              onClick={hadnleEditAvatar}
            >
              <img
                src={edit}
                alt="pen"
                className="w-4 h-4"
              />
            </button>
          </div>

          {/* Domain and Description */}
          <div className="flex max-w-[550px] w-full flex-col">
            <div className="flex  justify-center w-full">
              <div className="w-[100%]">
                <div
                  className="relative"
                  data-te-dropdown-ref
                >
                  {primaryDomain !== "" ? (
                    <>
                      <button
                        className="gap-2 w-full px-4 bg-white border-b-[4px] border-cyan-500 font-bold flex justify-center items-center p-2 rounded-sm"
                        data-te-dropdown-toggle-ref
                      >
                        {displayDomain}
                      </button>
                      <ul
                        className="absolute w-full z-[1000] text-[#ededed] font-bold p-4 hidden list-none overflow-hidden rounded-sm border-none bg-cyan-500 shadow-lg [&[data-te-dropdown-show]]:block"
                        aria-labelledby="dropdownMenu"
                        data-te-dropdown-menu-ref
                      >
                        {userDomains.map((item) => (
                          <li
                            onClick={changeDisplayDomain}
                            className="py-1 mb-1 cursor-pointer transition-all ease-in-out duration-150 hover:bg-cyan-400 text-center px-2 rounded-sm"
                            key={item}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <button className="gap-2 w-full px-4 bg-white border-b-[4px] border-cyan-500 font-bold flex justify-center items-center p-2 rounded-sm">
                      No Domains
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="relative mt-2">
              <button
                className="absolute z-20 top-2 left-2 border p-[2px] rounded-sm bg-[#ffffff]"
                onClick={hadnleEditDescript}
              >
                <img
                  src={edit}
                  alt="pen"
                  className="w-5 h-5"
                />
              </button>
              <p className="w-full relative indent-8 pt-4 overflow-y-auto h-[120px] p-1 rounded-sm bg-[#212c37] text-center text-white font-bold">
                {domainDesc === "" ? "No description" : domainDesc}
              </p>
            </div>
          </div>
        </div>

        {/* Bottom row */}
        <div className="flex flex-wrap justify-center gap-1  w-full">
          {/* Generic Info Card */}
          <div className="w-full md:w-min max-w-[550px] md:max-w-none flex justify-center items-center flex-col flex-wrap gap-1">
            <ul className="bg-[#191d26] w-full text-white hover:border-[#3c4e60] border-2 border-[#212c37] text-center flex justify-center md:w-[350px] h-32 flex-col text-lg sm:text-xl font-bold p-3 rounded-sm">
              <li className="flex justify-center items-center gap-2">
                Primary:{" "}
                <span className="text-gray-300">
                  {" "}
                  {primaryDomain === "" ? "not set" : primaryDomain}
                </span>
              </li>
              <li className="flex justify-center items-center gap-2">
                Website:{" "}
                <span className="text-gray-300">
                  {" "}
                  {domainWebs === "" ? "not set" : domainWebs}{" "}
                </span>
                <button
                  className="p-[1px] rounded-sm bg-[#e8e8e8]"
                  onClick={hadnleEditWebsite}
                >
                  <img
                    src={edit}
                    alt="pen"
                    className="w-4 h-4"
                  />
                </button>
              </li>
              <li className="flex text-md justify-center items-center gap-2">
                Email:{" "}
                <span className="text-gray-300">
                  {" "}
                  {domainEmail === "" ? "not set" : domainEmail}{" "}
                </span>
                <button
                  className="p-[1px] rounded-sm bg-[#e8e8e8]"
                  onClick={hadnleEditEmail}
                >
                  <img
                    src={edit}
                    alt="pen"
                    className="w-4 h-4"
                  />
                </button>
              </li>
            </ul>

            <button
              onClick={handleChangeAll}
              className="w-full text-white font-bold hover:bg-[#3c4e60] bg-[#212c37] p-2 rounded-sm"
            >
              Edit all your data at once
            </button>
          </div>

          {/* Change primary domain */}
          <div className="flex bg-white py-2 rounded-sm w-96 flex-col justify-center items-center gap-1">
            <div className="bg-[#212C37] p-2 w-[90%] rounded-sm flex flex-col gap-1 justify-center items-center">
              <h3 className="text-xl font-sans text-white font-bold text-center">
                Change Primary Domain
              </h3>
              <div className="flex justify-center w-full">
                <div className="w-[100%]">
                  <div
                    className="relative"
                    data-te-dropdown-ref
                  >
                    {primaryDomain !== "" ? (
                      <>
                        <button
                          className="gap-2 w-full px-4 bg-[#31404f] font-bold text-[#f2f2f2] flex justify-center items-center p-2 rounded-sm"
                          data-te-dropdown-toggle-ref
                        >
                          {primaryDomain}
                        </button>
                        <ul
                          className="absolute w-full z-[1000] text-[#ededed] font-bold p-4 hidden list-none overflow-hidden rounded-sm border-none bg-[#2c3946] shadow-lg [&[data-te-dropdown-show]]:block"
                          aria-labelledby="dropdownMenu"
                          data-te-dropdown-menu-ref
                        >
                          {userDomains
                            ?.filter((item) => item !== primaryDomain)
                            .map((item) => (
                              <li
                                onClick={changePrimary}
                                className="py-1 mb-1 cursor-pointer transition-all ease-in-out duration-150 hover:bg-[#51667b] text-center px-2 rounded-sm"
                              >
                                {item}
                              </li>
                            ))}
                        </ul>
                      </>
                    ) : (
                      <button className="gap-2 w-full px-4 bg-[#31404f] font-bold text-[#f2f2f2] flex justify-center items-center p-2 rounded-sm">
                        No Domains
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleDomainTransfer}
              className="gap-2 w-[90%] px-4 bg-[#191D26] font-bold text-[#f2f2f2]  flex justify-center items-center p-2 rounded-sm"
            >
              Transfer a Domain
            </button>
          </div>
        </div>

        {/* Subdomain title */}
        {primaryDomain !== "" ? (
          <h2 className="flex  flex-wrap justify-center text-[#ffffff] text-3xl md:text-4xl font-bold mb-2 mt-20 text-center">
            <span className="">
              Your subdomains for: <br className="md:hidden" />
              <span className="text-[#38c7e7] capitalize">{displayDomain}</span>
            </span>{" "}
          </h2>
        ) : (
          <div className="flex flex-col items-center mt-5">
            <h2 className="flex text-[#ffffff] text-2xl md:text-4xl font-bold mb-2 mt-20 text-center">
              You Have No Domains
            </h2>
          </div>
        )}

        {/* subdomains List */}
        {primaryDomain !== "" && (
          <>
            <div className="max-w-4xl rounded flex items-center justify-center w-full font-bold text-lg py-2 bg-[#c6c6c600] mt-2">
              <button
                onClick={() => setNewSubModal(true)}
                className="rounded mb-10 p-2 hover:bg-[#1a2130] text-[#38c7e7] border-2 border-[#38c7e7]"
              >
                New Subdomain +
              </button>
            </div>

            {/* subdomains List */}
            <ul className="mt-10 max-w-4xl flex justify-center flex-wrap gap-2">
              {subdomainList?.length !== 0 ? (
                subdomainList?.map((item) => (
                  <UserSubDomainlistItem
                    key={item}
                    signer={signer}
                    subdomain={item}
                    registrarAdd={registrarAdd}
                    parent={displayDomain}
                    refresher={fetchInitialData}
                  />
                ))
              ) : (
                <div className="items-center gap-10 flex-col max-w-screen-md flex font-bold text-center text-xl text-white">
                  You have no submains yet.
                  <img
                    src={empty}
                    alt=""
                    className="w-32"
                  />
                </div>
              )}
            </ul>
          </>
        )}
      </div>

      <Link to="/">
        <button className="text-white fixed bottom-5 right-5 md:right-12 md:bottom-12 bg-gradient-to-r h-12 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br font-bold rounded-sm text-sm px-5 py-2.5 text-center">
          Back to Home
        </button>
      </Link>
    </section>
  );
};

export default User;
