import { ethers } from "ethers";
import { useContext, useState } from "react";
import registrarAbi from "../../../contracts/registrar_abi.json";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import edit from "../../../assets/icons/edit.png";
import registryAbi from "../../../contracts/registry_abi.json";
import { AppContext } from "../../../context/appContext";
import warning from "../../../assets/icons/Shield Warning.svg";

const TransferDomain = ({
  setVisibility,
  signer,
  balance,
  domains,
  address,
  refresher,
}) => {
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();

  const [newDomainOwner, setnewDomainOwner] = useState("");
  const [isValidAdd, setValidAddr] = useState(true);
  const [selectedDomain, setselectedDomain] = useState(domains[0]);

  const { registryAdd } = useContext(AppContext);

  const registryContract = new ethers.Contract(
    registryAdd,
    registryAbi,
    signer
  );

  const handleOwnerChange = async (e) => {
    setValidAddr(true);
    setnewDomainOwner(e.target.value);

    if (!ethers.utils.isAddress(e.target.value)) return setValidAddr(false);
  };

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const transferDomain = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    console.log(selectedDomain, balance);

    const parsedText = ethers.utils.formatBytes32String(selectedDomain);
    let id;

    for (let i = 0; i < balance; i++) {
      const data = await registryContract.tokenOfOwnerByIndex(address, i);
      const formatedData = ethers.utils.formatUnits(data, 0);
      const domain = await registryContract.tokenToDomain(formatedData);

      if (domain === parsedText) {
        id = formatedData;
      }
    }

    console.log("id", id);

    try {
      const tranferDomain = await registryContract.transferFrom(
        address,
        newDomainOwner,
        id
      );

      await tranferDomain.wait();
      setVisibility(false);
      refresher();
    } catch (error) {
      console.log(error);
      setVisibility(false);
    }
  };

  return (
    <>
      <section
        onClick={() => setVisibility(false)}
        className="fixed w-full bottom-0 right-0 left-0 top-0 bg-[#000000e0] z-50 flex items-center justify-center"
      ></section>
      <div className="bg-white w-[350px] fixed -translate-y-[50%] z-[51] -translate-x-[50%] left-[50%] top-[50%] py-10 flex flex-col items-center rounded-sm">
        <h2 className="text-xl flex items-center gap-2 mb-4 font-bold">
          Transfering
          <div className="flex justify-center w-full">
            <div className="min-w-[200px]">
              <div
                className="relative"
                data-te-dropdown-ref
              >
                <>
                  <button
                    data-te-dropdown-toggle-ref
                    className="gap-2 w-full px-4 bg-[#31404f] font-bold text-[#f2f2f2] flex justify-center items-center p-2 rounded-sm"
                  >
                    {selectedDomain}
                  </button>

                  <ul
                    className="absolute w-full z-[1000] text-[#ededed] font-bold p-4 hidden list-none overflow-hidden rounded-sm border-none bg-[#2c3946] shadow-lg [&[data-te-dropdown-show]]:block"
                    aria-labelledby="dropdownMenu"
                    data-te-dropdown-menu-ref
                  >
                    {domains.map((item) => (
                      <li
                        onClick={() => setselectedDomain(item)}
                        className="py-1 mb-1 cursor-pointer transition-all ease-in-out duration-150 hover:bg-[#51667b] text-center px-2 rounded-sm"
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </>
              </div>
            </div>
          </div>
        </h2>
        <input
          spellCheck={false}
          value={newDomainOwner}
          onChange={handleOwnerChange}
          placeholder="new owner"
          type="text"
          className="bg-[#191D26] text-center mt-1 rounded-sm p-2 text-white max-w-[300px] w-full"
        />

        {!isValidAdd && newDomainOwner !== "" && (
          <div className="max-w-[300px] border-l-[4px] bg-[#DADBDB] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center mt-1 font-bold w-full py-2 text-sm text-center">
            <img
              src={warning}
              alt=""
              className="w-8"
            />
            This is not a valid address!
          </div>
        )}

        {/* buttons */}
        <div className="flex gap-2 mt-10">
          <button
            onClick={() => setVisibility(false)}
            className="p-2 bg-[#ebebeb] rounded-sm font-semibold px-4"
          >
            Cancel
          </button>
          <button
            onClick={transferDomain}
            className="p-2 bg-[#363b48] px-8 font-bold rounded-sm text-white"
          >
            Transfer
          </button>
        </div>
      </div>
    </>
  );
};

export default TransferDomain;
