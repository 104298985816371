import { Link } from "react-router-dom";
import { addressShortener } from "../../../utils/index";
import { ethers } from "ethers";
import avatar from "../../../assets/icons/avatar.png";

const DomainlistItem = ({ domain, owner }) => {
  const formatedName =
    (ethers.utils.parseBytes32String(domain) + ".inu").length > 13
      ? ethers.utils.parseBytes32String(domain).slice(0, 11) + ".."
      : ethers.utils.parseBytes32String(domain) + ".inu";

  return (
    <Link
      to={`/${ethers.utils.parseBytes32String(domain)}`}
      key={domain}
    >
      <li
        key={domain}
        className="bg-[#191d26] hover:border-[#3c4e60] text-white w-44 md:w-60 border-2 border-[#212c37] h-16 md:h-20 px-2 gap-2 flex items-center justify-start rounded-sm cursor-pointer text-center"
      >
        <div className="w-[32px] md:w-[48px] h-[32px] md:h-[48px] flex justify-center items-center">
          <img
            src={avatar}
            alt=""
            className="w-8 md:w-12"
          />
        </div>
        <div className="flex flex-col items-start">
          <h2 className="font-bold text-md md:text-xl">{formatedName} </h2>

          <h2 className="font-semibold text-xs text-gray-400">
            {addressShortener(owner)}
          </h2>
        </div>
      </li>
    </Link>
  );
};

export default DomainlistItem;
