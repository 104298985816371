import { useContext, useEffect, useState } from "react";
import { addressShortener } from "./utils";

import { ethers } from "ethers";
import registrarAbi from "./contracts/registrar_abi.json";
import registryAbi from "./contracts/registry_abi.json";

import SubDomainlistItem from "./components/UI/SubdomainItem";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AppContext } from "./context/appContext";
import CopyToClipboard from "react-copy-to-clipboard";
import LocalLoading from "./components/UI/LocalLoading";
import placeHodl from "./assets/icons/paw.png";
import empty from "./assets/icons/empty.png";

const Domain = () => {
  const { id } = useParams();
  const [subdomainList, setSubdomainList] = useState([]);
  const [registrarAdd, setRegistrarAdd] = useState("");
  const [domainOwner, setDomainOwner] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [addressCopied, setAddressCopied] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  const [domainDesc, setDomainDesc] = useState("");
  const [domainAvatar, setDomainAvatar] = useState("");
  const [domainEmail, setDomainEmail] = useState("");
  const [domainWebsite, setDomainWebsite] = useState("");

  const { registryAdd, staticProvider } = useContext(AppContext);

  const readRegistry = new ethers.Contract(
    registryAdd,
    registryAbi,
    staticProvider
  );

  const setCopyTimer = (input) => {
    if (input === "address") {
      setAddressCopied(true);
      setTimeout(() => {
        setAddressCopied(false);
      }, 2000);
    } else if (input === "email") {
      setEmailCopied(true);
      setTimeout(() => {
        setEmailCopied(false);
      }, 2000);
    }
  };

  const fakeDescription =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui totam atque distinctio eos odio saepe aspernatur! Suscipit, iusto atque possimus, placeat eius voluptatem magnam amet inventore cumque exipsam praesentium";

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);

      const formatedId = ethers.utils.formatBytes32String(id);

      try {
        const data = await readRegistry.registry(formatedId);
        setDomainOwner(data.owner);
        setRegistrarAdd(data.registrar);

        const readRegistrar = new ethers.Contract(
          data.registrar,
          registrarAbi,
          staticProvider
        );

        const ownerData = await readRegistrar.ownerInfo();

        setDomainDesc(ownerData.description);
        setDomainAvatar(ownerData.avatar);
        setDomainEmail(ownerData.email);
        setDomainWebsite(ownerData.website);

        const subdomains = await readRegistrar.getAllSubdomains();

        setSubdomainList(subdomains);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <section className="w-full flex justify-center">
      <div className="max-w-screen-2xl flex p-1 sm:px-4 flex-col items-center justify-center min-h-screen w-full">
        {/* Avatar */}
        <div className="w-full sm:mt-2 mb-5 flex flex-col gap-2 items-center">
          <div className="max-w-[730px] mt-20 gap-2 w-full flex justify-center flex-wrap items-center">
            {/* Avatar */}
            <div className="shadow-2xl w-52 h-52 rounded-sm bg-[#fdfdfd]">
              <img
                src={domainAvatar !== "" ? domainAvatar : placeHodl}
                alt="avatar"
                className="rounded-sm w-full h-full"
              />
            </div>

            {/* Domain and description */}
            <div className="flex shadow-2xl h-52 flex-col w-full max-w-lg">
              <h2 className="p-2 border-b-[3px] border-cyan-300 rounded-sm rounded-b-none bg-white min-w-[300px] flex justify-center items-center font-bold text-2xl">
                {id}.inu
              </h2>
              <p className="max-w-lg h-full flex flex-wrap break-words overflow-y-auto justify-center items-center bg-[#191D26] px-4 py-2 rounded-t-none rounded-md text-center text-white font-bold">
                {domainDesc === "" ? "No description available." : domainDesc}
              </p>
            </div>
          </div>

          {/* Links */}
          <div className="gap-2 text-cyan-300 max-w-[730px] text-center flex flex-col items-center md:flex-row w-full justify-center">
            <CopyToClipboard text={domainOwner}>
              <div
                onClick={() => setCopyTimer("address")}
                className="bg-[#191d26] max-w-[400px] md:max-w-[240px] hover:border-[#3c4e60] text-white border-2 border-[#212c37] cursor-pointer h-10 w-full p-2 rounded-sm"
              >
                {addressCopied ? (
                  "Address Copied!"
                ) : (
                  <>Owner: {addressShortener(domainOwner)}</>
                )}
              </div>
            </CopyToClipboard>

            <a
              href={domainWebsite !== "" ? `//${domainWebsite}` : "/"}
              target="_blank"
              rel="noreferrer noopener"
              className="bg-[#191d26] max-w-[400px] md:max-w-[240px] w-full items-center flex px-2 hover:border-[#3c4e60] h-10 text-white border-2 border-[#212c37] overflow-x-hidden rounded-sm"
            >
              <div className="flex gap-1 overflow-x-hidden max-w-[240px] w-full">
                <span>
                  {domainWebsite !== "" ? domainWebsite : "Website Not set"}
                </span>
              </div>
            </a>

            <CopyToClipboard text={domainEmail}>
              <div
                onClick={() => setCopyTimer("email")}
                className="cursor-pointer flex gap-1 bg-[#191d26] hover:border-[#3c4e60] text-white border-2 border-[#212c37] max-w-[400px] md:max-w-[240px] w-full h-10 p-2 rounded-sm"
              >
                <span>
                  {domainEmail !== ""
                    ? emailCopied
                      ? "Email Copied"
                      : " " + domainEmail
                    : "Email Not set"}
                </span>
              </div>
            </CopyToClipboard>
          </div>
        </div>

        <h2 className="flex rounded-r-lg text-[#ffffff] text-4xl font-bold mb-2 mt-10 text-center">
          Subdomains
        </h2>

        {/* subdomains List */}
        <ul className="mt-20 max-w-4xl flex justify-center flex-wrap gap-2">
          {isLoading ? (
            <span className="text-3xl text-gray-200">
              <LocalLoading text={"Loading Subdomains"} />
            </span>
          ) : (
            <>
              {subdomainList?.length !== 0 ? (
                subdomainList?.map((item) => (
                  <SubDomainlistItem
                    parent={id}
                    sub={ethers.utils.parseBytes32String(item)}
                    registrarAdd={registrarAdd}
                  />
                ))
              ) : (
                <div className="items-center gap-10 flex-col max-w-screen-md flex font-bold text-center text-xl text-white">
                  Sorry, this domain <br /> hasn't created any submains yet.
                  <img
                    src={empty}
                    alt=""
                    className="w-32"
                  />
                </div>
              )}
            </>
          )}
        </ul>
      </div>

      <Link to="/">
        <button className="text-white fixed bottom-5 right-5 md:right-12 md:bottom-12 font-bold bg-gradient-to-r h-12 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br rounded-sm text-sm px-5 py-2.5 text-center">
          Back to Home
        </button>
      </Link>
    </section>
  );
};

export default Domain;
