import styles from "./styles.module.css";

const LocalLoading = ({ text }) => {
  return (
    <div className="flex flex-col items-center">
      <div className={styles.loading}></div>
      {text !== "none" && (
        <h2 className="text-2xl text-[#3ebce2] mt-10">{text} . . .</h2>
      )}
    </div>
  );
};

export default LocalLoading;
