import { ethers } from "ethers";
import { useContext, useState } from "react";
import registrarAbi from "../../../contracts/registrar_abi.json";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { AppContext } from "../../../context/appContext";
import Loading from "../LoadingAnimation/Loading";
import { successToast } from "../ToastAlerts";

const EditOwnerInfoAll = ({
  setVisibility,
  signer,
  registrarAdd,
  condition,
  refresher,
  displayDom,
}) => {
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();

  const { globalLoading, setGlobalLoading, setLoadingMessage } =
    useContext(AppContext);

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescChange = async (e) => {
    setDescription(e.target.value);
  };
  const handleWebsChange = async (e) => {
    setWebsite(e.target.value);
  };
  const handleAvatcChange = async (e) => {
    setAvatar(e.target.value);
  };

  const handleEmailChange = async (e) => {
    setEmail(e.target.value);
  };

  const changeOnwerInfo = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    const registrarContract = new ethers.Contract(
      registrarAdd,
      registrarAbi,
      signer
    );

    setGlobalLoading(true);
    setLoadingMessage("Computing Transaction");

    if (condition === "all") {
      try {
        const editData = await registrarContract.setOwnerData(
          description,
          website,
          email,
          avatar
        );
        await editData.wait();
        setVisibility(false);
        refresher(displayDom);
        setGlobalLoading(false);
      } catch (error) {
        console.log(error);
        setVisibility(false);
        setGlobalLoading(false);
      }
    } else if (condition === "avatar") {
      try {
        const editData = await registrarContract.setOwnerAvatar(avatar);

        await editData.wait();
        setVisibility(false);
        refresher(displayDom);
        setGlobalLoading(false);
        successToast("Avatar Changed!");
      } catch (error) {
        console.log(error);
        setVisibility(false);
        setGlobalLoading(false);
      }
    } else if (condition === "description") {
      try {
        const editData = await registrarContract.setOwnerDescription(
          description
        );

        await editData.wait();
        setVisibility(false);
        refresher(displayDom);
        setGlobalLoading(false);
        successToast("Description Changed!");
      } catch (error) {
        console.log(error);
        setVisibility(false);
        setGlobalLoading(false);
      }
    } else if (condition === "email") {
      try {
        const editData = await registrarContract.setOwnerEmail(email);

        await editData.wait();
        setVisibility(false);
        refresher(displayDom);
        setGlobalLoading(false);
        successToast("Email Changed!");
      } catch (error) {
        console.log(error);
        setVisibility(false);
        setGlobalLoading(false);
      }
    } else {
      try {
        const editData = await registrarContract.setOwnerWebsite(website);

        await editData.wait();
        setVisibility(false);
        refresher(displayDom);
        setGlobalLoading(false);
        successToast("Website Changed!");
      } catch (error) {
        console.log(error);
        setVisibility(false);
        setGlobalLoading(false);
      }
    }
  };

  return (
    <>
      <section
        onClick={() => setVisibility(false)}
        className="fixed w-full bottom-0 right-0 left-0 top-0 bg-[#000000e0] z-50 flex items-center justify-center"
      ></section>

      {globalLoading && <Loading />}
      <div className="bg-white w-[350px] fixed -translate-y-[50%] z-[51] -translate-x-[50%] left-[50%] top-[50%] py-5 flex flex-col items-center">
        <h2 className="text-xl mb-4 font-bold">
          Edit Owner Info For{" "}
          <span className="bg-[#0999] p-[1px] px-2 rounded-sm">
            {displayDom}
          </span>
        </h2>
        {(condition === "all" || condition === "avatar") && (
          <img
            src={
              avatar !== ""
                ? avatar
                : "https://imgs.search.brave.com/poNnaqRebxpPLTVSB0hS5am3GhVRCX5FtoJNhvc6aI8/rs:fit:300:300:1/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vd3d3LnJl/cG9sLmNvcGwudWxh/dmFsLmNhL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDE5LzAxL2Rl/ZmF1bHQtdXNlci1p/Y29uLmpwZz9maXQ9/MzAwJTJDMzAw"
            }
            alt="avatar"
            className="w-44 mt-3 h-44 rounded-sm shadow-xl mb-5"
          />
        )}
        {(condition === "all" || condition === "website") && (
          <input
            spellCheck={false}
            value={website}
            onChange={handleWebsChange}
            placeholder="website"
            type="text"
            className="bg-[#202530] max-w-[300px] w-full text-center mt-1 shadow-xl p-2 text-white"
          />
        )}
        {(condition === "all" || condition === "avatar") && (
          <input
            spellCheck={false}
            value={avatar}
            onChange={handleAvatcChange}
            placeholder="avatar"
            type="text"
            className="bg-[#202530] max-w-[300px] w-full text-center mt-1 shadow-xl p-2 text-white"
          />
        )}
        {(condition === "all" || condition === "description") && (
          <input
            spellCheck={false}
            value={description}
            onChange={handleDescChange}
            placeholder="description"
            type="text"
            className="bg-[#202530] max-w-[300px] w-full text-center mt-1 shadow-xl p-2 text-white"
          />
        )}
        {(condition === "all" || condition === "email") && (
          <input
            spellCheck={false}
            value={email}
            onChange={handleEmailChange}
            placeholder="email"
            type="text"
            className="bg-[#202530] max-w-[300px] w-full text-center mt-1 shadow-xl p-2 text-white"
          />
        )}

        {/* buttons */}
        <div className="flex gap-2 mt-10">
          <button
            onClick={() => setVisibility(false)}
            className="p-2 bg-[#ebebeb] rounded-sm font-semibold px-4"
          >
            Cancel
          </button>
          <button
            onClick={changeOnwerInfo}
            className="p-2 bg-[#363b48] font-bold rounded-sm text-white"
          >
            Change Data
          </button>
        </div>
      </div>
    </>
  );
};

export default EditOwnerInfoAll;
