import { ethers } from "ethers";
import registrarAbi from "../../../contracts/registrar_abi.json";
import { useState } from "react";
import { addressShortener } from "../../../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../../assets/icons/copy.png";
import CardLoading from "../CardLoading";

const SubDomainlistItem = ({ parent, sub, registrarAdd }) => {
  const [viewMode, setViewMode] = useState(false);
  const [description, setDescription] = useState("");
  const [target, setTarget] = useState("");
  const [copied, setCopied] = useState(false);

  const [loading, setLoading] = useState(false);

  const staticProvider = new ethers.providers.JsonRpcProvider(
    "https://puppynet.shibrpc.com"
  );

  const readRegistrarContract = new ethers.Contract(
    registrarAdd,
    registrarAbi,
    staticProvider
  );

  const openInfoCard = async () => {
    setViewMode(true);
    setLoading(true);

    const parsedText = ethers.utils.formatBytes32String(sub);
    const data = await readRegistrarContract.subDomainData(parsedText);
    setDescription(data.description);
    setTarget(data.target);

    setLoading(false);
  };

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      {viewMode && (
        <>
          <div
            onClick={() => setViewMode(false)}
            className="fixed flex justify-center items-center z-10 right-0 left-0 bg-[#1111111c] bottom-0 top-0"
          ></div>

          <div className="shadow-xl border-[3px] text-[rgb(255,255,255)] border-[#212c37] bg-[#13161d] rounded-sm fixed overflow-y-auto flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-[360px] h-[350px]">
            <div className="mb-10 w-full flex flex-col bg-[#222c36] items-center py-2">
              <h2 className="text-2xl">{sub}</h2>
              <span className="text-lg">@{parent}.inu</span>
            </div>

            {loading ? (
              <div className="mt-16">
                <CardLoading />
              </div>
            ) : (
              <>
                <CopyToClipboard
                  className="cursor-pointer py-2 items-center justify-center gap-2 flex w-full text-xl mb-5"
                  text={target}
                >
                  {copied ? (
                    <span>Address Copied!</span>
                  ) : (
                    <div
                      onClick={handleCopy}
                      className="flex"
                    >
                      <span className="text-lg">
                        Address:{" "}
                        {target === ""
                          ? "Loading..."
                          : addressShortener(target)}
                      </span>
                      <img
                        src={copy}
                        alt=""
                        className="w-5 h-5"
                      />
                    </div>
                  )}
                </CopyToClipboard>
                <h3 className="text-lg"> Description:</h3>
                <span className="text-md text-center p-3">
                  {description === "" ? "Loading..." : description}
                </span>
              </>
            )}
          </div>
        </>
      )}

      <li
        onClick={openInfoCard}
        className="bg-[#191d26] hover:border-[#3c4e60] text-white w-44 md:w-60 border-2 border-[#212c37] h-20 px-2 gap-2 flex flex-col justify-center items-center rounded-sm cursor-pointer text-center"
      >
        <h2 className="font-bold leading-[20px] text-lg md:text-xl">{sub}</h2>
        <h2 className="font-bold leading-[10px] md:leading-[18px] text-gray-400 text-sm md:text-md">
          {parent}.inu
        </h2>
      </li>
    </>
  );
};

export default SubDomainlistItem;
