import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <section className="fixed w-full bottom-0 right-0 left-0 top-0 bg-[#000000e0] z-50 flex items-center justify-center">
      <div className="bg-white px-3 text-center w-[360px] fixed -translate-y-[50%] z-[120] -translate-x-[50%] left-[50%] top-[50%] py-10 flex flex-col items-center rounded-sm">
        <h2 className="text-xl mb-4 font-bold">
          You're not connected or dont own any domain!
        </h2>
        <Link to="/">
          <Link to="/">
            <button className="text-white bg-gradient-to-r h-12 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br font-bold rounded-sm text-sm px-5 py-2.5 text-center">
              Back to Home
            </button>
          </Link>
        </Link>
      </div>
    </section>
  );
};

export default ErrorPage;
