import { ethers } from "ethers";
import { useContext, useState } from "react";
import registrarAbi from "../../../contracts/registrar_abi.json";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { AppContext } from "../../../context/appContext";
import warning from "../../../assets/icons/Shield Warning.svg";
import Loading from "../LoadingAnimation/Loading";
import { errorToast, successToast } from "../ToastAlerts";

const NewSubdomainModal = ({
  setVisibility,
  signer,
  registrarAdd,
  refresher,
}) => {
  const [newSubdomain, setNewSubdomain] = useState("");
  const [newSubdomainOwner, setNewSubdomainOwner] = useState("");
  const [available, setAvailable] = useState(false);
  const [hasAlready, setHasAlready] = useState(false);
  const [validAddr, setValidAddr] = useState(true);

  const [description, setDescription] = useState("");

  const { staticProvider, globalLoading, setLoadingMessage, setGlobalLoading } =
    useContext(AppContext);

  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const readRegistrarContract = new ethers.Contract(
    registrarAdd,
    registrarAbi,
    staticProvider
  );

  const handleDomainChange = (e) => {
    validateAddress(
      e.target.value
        .replace(".", "")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
    );

    setNewSubdomain(
      e.target.value
        .replace(".", "")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .slice(0, 32)
    );
  };

  const validateAddress = async (text) => {
    const parsedText = ethers.utils.formatBytes32String(text);
    const call = await readRegistrarContract.registered(parsedText);
    setAvailable(call);
    console.log(call);
  };

  const handleOwnerChange = async (e) => {
    setNewSubdomainOwner(e.target.value);

    console.log("is address", ethers.utils.isAddress(e.target.value));
    setValidAddr(ethers.utils.isAddress(e.target.value));

    checkHasSubDomain(e.target.value);
  };

  const handleDescChange = async (e) => {
    setDescription(e.target.value);
  };

  const checkHasSubDomain = async (text) => {
    const call = await readRegistrarContract.hasSubDomain(text);
    setHasAlready(call);
    console.log("has", call);
  };

  const createNewSubdomain = async () => {
    const parsedText = ethers.utils.formatBytes32String(newSubdomain);

    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    console.log("ref", registrarAdd);
    const registrarContract = new ethers.Contract(
      registrarAdd,
      registrarAbi,
      signer
    );

    try {
      setLoadingMessage("Creating new Subdomain");
      setGlobalLoading(true);

      const newSubDom = await registrarContract.setNewSubdomain(
        parsedText,
        newSubdomainOwner,
        description
      );

      await newSubDom.wait();
      setVisibility(false);

      refresher();
      successToast("New Subdomain Created!");
      setGlobalLoading(false);
    } catch (error) {
      console.log(error);

      errorToast("Something Went Wrong!");
      setGlobalLoading(false);
    }
  };

  return (
    <>
      {globalLoading && <Loading />}
      <section
        onClick={() => setVisibility(false)}
        className="fixed w-full bottom-0 right-0 left-0 top-0 bg-[#000000b0] z-50 flex items-center justify-center"
      ></section>
      <div className="bg-[#f7feff] text-white w-[350px] fixed -translate-y-[50%] z-[51] -translate-x-[50%] left-[50%] top-[50%] py-10 flex flex-col items-center rounded-sm">
        <h2 className="text-xl text-black mb-7 font-bold">
          Create New Subdomain
        </h2>

        {available && (
          <div className=" mb-4 border-l-[4px] w-[280px] bg-[#d7d7d7e3] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center font-bold py-2 text-md text-center">
            <img
              src={warning}
              alt=""
              className="w-6"
            />
            This subdomain already exists
          </div>
        )}

        <input
          value={newSubdomain}
          onChange={handleDomainChange}
          placeholder="subdomain name"
          type="text"
          className="w-[280px] bg-[#202530] mb-2 text-center placeholder:text-[#898989] rounded-sm h-10 px-2 italic font-bold shadow-md outline-none"
        />

        <input
          spellCheck={false}
          value={newSubdomainOwner}
          onChange={handleOwnerChange}
          placeholder="owner"
          type="text"
          className="w-[280px] bg-[#202530] mb-2 text-center placeholder:text-[#898989] rounded-sm h-10 px-2 italic font-bold shadow-md outline-none"
        />

        {!validAddr && newSubdomainOwner !== "" && (
          <div className=" mb-4 border-l-[4px] w-[280px] bg-[#d7d7d7e3] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center font-bold py-1 text-md text-center">
            <img
              src={warning}
              alt=""
              className="w-6"
            />
            Not a valid address.
          </div>
        )}

        {validAddr && (
          <>
            {hasAlready && (
              <div className=" mb-4 border-l-[4px] w-[280px] bg-[#d7d7d7e3] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center font-bold py-1 text-sm text-center">
                <img
                  src={warning}
                  alt=""
                  className="w-6"
                />
                Address already has a subdomain
              </div>
            )}
          </>
        )}

        <input
          spellCheck={false}
          value={description}
          onChange={handleDescChange}
          placeholder="description"
          type="text"
          className="w-[280px] bg-[#202530] mb-2 text-center placeholder:text-[#898989] rounded-sm h-10 px-2 italic font-bold shadow-md outline-none"
        />

        {/* buttons */}
        <div className="flex mt-4 gap-2">
          <button
            onClick={() => {
              errorToast("Domain Creation Canceled");
              setVisibility(false);
            }}
            className="text-white mt-5 bg-gradient-to-r h-10 from-[#C34B42] via-[#b64840] to-[#a83f37] hover:bg-gradient-to-br font-bold rounded-sm text-sm px-5 py-2.5 text-center"
          >
            Cancel
          </button>
          <button
            onClick={createNewSubdomain}
            className="text-white mt-5 bg-gradient-to-r h-10 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br font-bold rounded-dm text-sm px-5 py-2.5 text-center"
          >
            Create Sudomain
          </button>
        </div>
      </div>
    </>
  );
};

export default NewSubdomainModal;
