import { useWeb3Modal } from "@web3modal/react";
import { Link, NavLink } from "react-router-dom";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { addressShortener } from "../../utils";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { ethers } from "ethers";
import registryAbi from "../../contracts/registry_abi.json";
import { AppContext } from "../../context/appContext";
import profile from "../../assets/icons/resume.png";

const Header = () => {
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [primaryDomain, setPrimaryDomain] = useState("");

  const { address, isConnected } = useAccount();

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const { registryAdd, staticProvider } = useContext(AppContext);

  const readRegistryContract = new ethers.Contract(
    registryAdd,
    registryAbi,
    staticProvider
  );

  const fetchPrimaryDomain = async () => {
    if (isConnected) {
      const name = await readRegistryContract.primaryDomain(address);
      setPrimaryDomain(ethers.utils.parseBytes32String(name));
    }
  };

  useEffect(() => {
    fetchPrimaryDomain();
  }, [isConnected, address]);

  return (
    <header className="w-full py-2 items-center bg-[#1c1c1c] flex justify-center px-2">
      <div className="flex justify-between gap-1 max-w-screen-2xl w-full items-center">
        <h1>
          <Link to="/">
            <button className="text-white text-sm from-cyan-400 via-cyan-500 to-cyan-600 bg-gradient-to-br font-bold rounded-sm border-cyan-600 h-12 w-48">
              Shibarium Name Service
            </button>
          </Link>
        </h1>

        <div className="flex gap-1 md:gap-2">
          {isConnected ? (
            <button className="border-2 flex justify-center items-center font-bold rounded-sm text-cyan-500 border-cyan-600 h-12 w-36">
              {/* larger screen */}
              <span className="hidden md:flex">
                {primaryDomain !== "" ? (
                  <>{primaryDomain.slice(0, 11)}.inu</>
                ) : (
                  addressShortener(address)
                )}
              </span>

              {/* smaller screen*/}
              <span className="md:hidden">
                {primaryDomain !== "" ? (
                  <>
                    {primaryDomain.length > "7"
                      ? primaryDomain.slice(0, 8) + "..."
                      : primaryDomain + "inu"}
                  </>
                ) : (
                  addressShortener(address)
                )}
              </span>
            </button>
          ) : (
            <button
              onClick={connectWallet}
              className="text-sm border-2 flex justify-center items-center font-bold rounded-sm text-cyan-500 border-cyan-600 h-12 md:text-base w-28 md:w-36"
            >
              <span className="md:hidden">Connect</span>
              <span className="hidden md:flex">Connect Wallet</span>
            </button>
          )}
          <Link to="/user">
            <button className="text-white flex justify-center items-center bg-gradient-to-r h-12 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br font-bold rounded-sm text-sm px-2 md:px-5 py-2.5 text-center">
              <span className="hidden md:flex">My Domains</span>
              <img
                src={profile}
                alt=""
                className="w-5 md:hidden"
              />
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
