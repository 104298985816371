import { ethers } from "ethers";
import { createContext, useState } from "react";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading");

  const registryAdd = "0xb1330C31c14d51Af7d934b9b1A816ec2B67D515e";

  const staticProvider = new ethers.providers.JsonRpcProvider(
    "https://www.shibrpc.com"
  );

  return (
    <AppContext.Provider
      value={{
        registryAdd,
        staticProvider,
        globalLoading,
        setGlobalLoading,
        loadingMessage,
        setLoadingMessage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
