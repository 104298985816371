import { useContext, useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { Link } from "react-router-dom";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import DomainlistItem from "./components/UI/DomainItem";
import { ethers } from "ethers";
import registryAbi from "./contracts/registry_abi.json";
import search from "./assets/icons/search.png";
import axios from "axios";
import { AppContext } from "./context/appContext";
import follow from "./assets/icons/redirect.png";
import warning from "./assets/icons/Shield Warning.svg";
import tag from "./assets/icons/tag.png";
import LocalLoading from "./components/UI/LocalLoading";
import Loading from "./components/UI/LoadingAnimation/Loading";
import { errorToast, successToast } from "./components/UI/ToastAlerts";

const Home = () => {
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  const [purchase, setPurchase] = useState(true);
  const [domainSlice, setDomainSlice] = useState(24);
  const [inputText, setInputText] = useState("");
  const [available, setAvailable] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [searchDomainList, setSearchDomainList] = useState([]);

  const [loading, setLoading] = useState(false);

  const staticProvider = new ethers.providers.JsonRpcProvider(
    "https://www.shibrpc.com"
  );

  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { registryAdd, setLoadingMessage, globalLoading, setGlobalLoading } =
    useContext(AppContext);

  const readRegistryContract = new ethers.Contract(
    registryAdd,
    registryAbi,
    staticProvider
  );

  const registryContract = new ethers.Contract(
    registryAdd,
    registryAbi,
    signer
  );

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputText = (e) => {
    validateAddress(
      e.target.value
        .replace(".", "")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
    );

    setInputText(
      e.target.value
        .replace(".", "")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .slice(0, 32)
    );
  };

  const handleFilterText = (e) => {
    setFilterText(
      e.target.value
        .replace(".", "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase()
        .trim()
    );
  };

  const validateAddress = async (text) => {
    const parsedText = ethers.utils.formatBytes32String(text);

    const call = await readRegistryContract.checkAvailable(parsedText);
    setAvailable(call);
  };

  const purchaseDomain = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    try {
      const payWithTokens = await registryContract.payWithTokens();
      const price = await registryContract.usdPrice();

      const amount = await registryContract.getPrice(price, payWithTokens);
      console.log(ethers.utils.formatUnits(amount, 0));

      setLoadingMessage("Registering New Domain");
      setGlobalLoading(true);

      const parsedText = ethers.utils.formatBytes32String(inputText);

      const newDomain = await registryContract.newDomain(parsedText, {
        value: amount,
      });

      await newDomain.wait();

      setGlobalLoading(false);

      successToast(
        `Congratulations! You are now the owner of: ${inputText}.inu`
      );

      fetchInitialData();
    } catch (error) {
      setGlobalLoading(false);
      errorToast("Something went wrong!");
      console.log(error);
    }
  };

  const loadMore = () => {
    setDomainSlice((prev) => prev + 24);
  };

  const fetchInitialData = async () => {
    setLoading(true);

    const response = await axios.get(
      "https://snsserverfinal.onrender.com/getAllDomains"
    );

    setDomainList(response?.data.reverse());

    setSearchDomainList(
      response?.data.map((item) => ethers.utils.parseBytes32String(item.domain))
    );

    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <section className="w-full flex justify-center">
      <div className="max-w-screen-2xl p-2 min-h-[90vh] w-full flex flex-col items-center ">
        {globalLoading && <Loading />}

        {/* Purchase new domain buttons*/}
        <div className="mt-20 md:mt-40 flex min-h-[400px] text-white max-w-[900px] border-2 border-[#212c37] w-full flex-col items-center bg-[#171b23] p-2 rounded-md">
          <div className="w-full flex md:justify-start justify-center p-1 gap-7 md:gap-10">
            <button
              onClick={() => setPurchase(true)}
              className={
                purchase
                  ? "text-cyan-500 border-b-2 border-cyan-500 font-bold text-lg sm:text-2xl"
                  : "text-white font-bold text-lg sm:text-2xl"
              }
            >
              Purchase Domain
            </button>
            <button
              onClick={() => setPurchase(false)}
              className={
                purchase
                  ? "text-white font-bold text-lg sm:text-2xl"
                  : "text-cyan-500 border-b-2 border-cyan-500 font-bold text-lg sm:text-2xl"
              }
            >
              Search Domain
            </button>
          </div>

          {purchase ? (
            <div className="mt-10 max-w-[700px] w-full flex flex-col items-center mb-2">
              <h2 className="md:text-2xl text-xl text-center font-semibold mb-10">
                Get you own Shibarium domain now!
              </h2>
              <input
                spellCheck={false}
                value={inputText}
                placeholder="mydomain.inu"
                onChange={handleInputText}
                type="text"
                className="w-full bg-[#202530] mb-2 text-center placeholder:text-[#898989] text-cyan-500 rounded-md h-10 px-2 italic font-bold shadow-md outline-none"
              />

              <div className="flex flex-col items-center">
                <span className="flex gap-1 italic text-xs md:text-lg mb-5 font-bold">
                  {inputText !== ""
                    ? inputText + ".inu"
                    : "Join Our Massive Community"}
                </span>

                {available ? (
                  <button
                    onClick={purchaseDomain}
                    className="text-white mb-10 mt-5 bg-gradient-to-r h-10 from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br font-bold rounded-sm text-sm px-5 py-2.5 text-center"
                  >
                    Mint Domain
                  </button>
                ) : (
                  <button
                    onClick={() => errorToast("Domain Already Taken!")}
                    className="text-white mb-10 mt-5 bg-gradient-to-r h-10 from-[#C34B42] via-[#b64840] to-[#a83f37] hover:bg-gradient-to-br font-bold rounded-sm text-sm px-5 py-2.5 text-center"
                  >
                    Not Available!
                  </button>
                )}
              </div>

              {/* Connect message */}
              {(!isConnected || chain?.id !== 109) && (
                <div className="border-l-[4px] bg-[#101215e3] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center mt-1 font-semibold md:font-bold w-full py-2 text-sm md:text-lg text-center">
                  <img
                    src={warning}
                    alt=""
                    className="w-6 md:w-8"
                  />
                  Please, connect and switch to Shibarium.
                </div>
              )}

              {!available && (
                <div className="border-l-[4px] bg-[#101215e3] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center mt-1 font-semibold md:font-bold w-full py-2 text-sm md:text-lg text-center">
                  <img
                    src={warning}
                    alt=""
                    className="w-6 md:w-8"
                  />
                  This domain is already taken!
                </div>
              )}
            </div>
          ) : (
            <div className="max-w-[700px] h-[350px] w-full flex flex-col items-center mt-12 mb-2">
              <h2 className="text-xl font-bold mb-5">Every Dog Has a Name</h2>
              <input
                spellCheck={false}
                value={filterText}
                placeholder="Search Domain"
                onChange={handleFilterText}
                type="text"
                className="bg-[#ffffff] max-w-[700px] w-full mb-2 text-center placeholder:text-[#898989] text-[#000000] rounded-md h-10 px-2 italic font-bold shadow-lg outline-none"
              />

              {filterText !== "" ? (
                <div className="absolute overflow-y-auto max-h-[250px] mt-24 max-w-[90%] w-full p-2 md:max-w-[700px] bg-[#11141A] shadow-lg gap-2 rounded-md py-2 flex flex-col items-center">
                  {searchDomainList.filter((item) => item.includes(filterText))
                    .length === 0 ? (
                    <div className="w-full flex flex-col items-center justify-center">
                      <span className="p-2 bg-[#11141A] border-2 border-[#3a4a59] text-center rounded-md font-bold w-full">
                        Not results found
                      </span>
                    </div>
                  ) : (
                    searchDomainList
                      .filter((item) => item.indexOf(filterText) !== -1)
                      .map((item) => (
                        <Link
                          className="w-full flex justify-center"
                          to={`/${item}`}
                        >
                          <div className="flex items-center justify-between p-2 bg-[#1b2029] text-center rounded-md font-semibold w-full">
                            <div className="flex items-center gap-2">
                              <img
                                src={tag}
                                alt=""
                                className="w-5 h-5"
                              />{" "}
                              {item}.inu
                            </div>

                            <img
                              src={follow}
                              alt=""
                              className="w-5 h-5"
                            />
                          </div>
                        </Link>
                      ))
                  )}
                </div>
              ) : (
                <img
                  src={search}
                  alt=""
                  className="w-32 my-16"
                />
              )}
            </div>
          )}
        </div>

        {loading ? (
          <div className="mt-20">
            <LocalLoading text={"Fetching Domains"} />
          </div>
        ) : (
          <ul className="mt-20 w-full mb-10 max-w-[1000px] flex justify-center flex-wrap gap-1 md:gap-2">
            {domainList?.length === 0 ? (
              <span className="text-white font-bold text-3xl">
                <LocalLoading text="Loading Domains" />
              </span>
            ) : (
              domainList?.slice(0, domainSlice).map((item) => (
                <DomainlistItem
                  domain={item.domain}
                  key={item.domain}
                  owner={item.owner}
                />
              ))
            )}
          </ul>
        )}
        {!loading && (
          <>
            {domainSlice < domainList.length && (
              <button
                onClick={loadMore}
                className="border-2 font-bold rounded-lg text-cyan-500 border-cyan-600 h-12 w-36"
              >
                Show More
              </button>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default Home;
