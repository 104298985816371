import { ethers } from "ethers";
import registrarAbi from "../../../contracts/registrar_abi.json";
import { useContext, useState } from "react";
import { addressShortener, longAddressCrop } from "../../../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../../assets/icons/copy.png";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import edit from "../../../assets/icons/edit.png";
import { AppContext } from "../../../context/appContext";
import Loading from "../LoadingAnimation/Loading";
import { errorToast, successToast } from "../ToastAlerts";
import warning from "../../../assets/icons/Shield Warning.svg";
import LocalLoading from "../LocalLoading";

const UserSubDomainlistItem = ({
  parent,
  subdomain,
  signer,
  registrarAdd,
  refresher,
}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [target, setTarget] = useState("");
  const [copied, setCopied] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [inputDesc, setInputDesc] = useState("");
  const [inputTarget, setInputTarget] = useState("");

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [hasSubdom, setHassubdom] = useState(false);

  const { staticProvider, globalLoading, setGlobalLoading, setLoadingMessage } =
    useContext(AppContext);

  const openInfoCard = async () => {
    setVisibility(true);
    getSubdomainInfo();
  };

  const getSubdomainInfo = async () => {
    setLocalLoading(true);
    const parsedText = ethers.utils.formatBytes32String(subdomain);
    const data = await readRegistrarContract.subDomainData(parsedText);
    setDescription(data.description);
    setTarget(data.target);
    setLocalLoading(false);
  };

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const [description, setDescription] = useState("");

  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const parsedSubdomain = ethers.utils.formatBytes32String(subdomain);

  const connectWallet = () => {
    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const readRegistrarContract = new ethers.Contract(
    registrarAdd,
    registrarAbi,
    staticProvider
  );
  console.log("wasd", registrarAdd);

  const handleDescChange = (e) => {
    setInputDesc(e.target.value);
  };

  const checkHasSubdom = async (add) => {
    if (!ethers.utils.isAddress(add)) return false;
    const bool = await readRegistrarContract.hasSubDomain(add);
    return bool;
  };

  const handleTargetChange = async (e) => {
    setInputTarget(e.target.value);

    const validate = ethers.utils.isAddress(e.target.value);
    setInvalidAddress(!validate);

    const data = await checkHasSubdom(e.target.value);
    console.log(data);
    setHassubdom(data);
  };

  const changeDescription = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    const registrarContract = new ethers.Contract(
      registrarAdd,
      registrarAbi,
      signer
    );

    const parsedText = ethers.utils.formatBytes32String(subdomain);

    try {
      setLoadingMessage("Changing Subdomain Description");
      setGlobalLoading(true);

      const editDescription =
        await registrarContract.changeSubdomainDescription(
          parsedText,
          inputDesc
        );

      await editDescription.wait();
      getSubdomainInfo();

      setGlobalLoading(false);
      successToast("Description Successfully Changed!");
    } catch (error) {
      console.log(error);
      setGlobalLoading(false);
      errorToast("Couldn't Change Subdomain Description");
    }
  };

  const changeTarget = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    if (invalidAddress || hasSubdom) return alert("Invalid Address");

    const registrarContract = new ethers.Contract(
      registrarAdd,
      registrarAbi,
      signer
    );

    const parsedText = ethers.utils.formatBytes32String(subdomain);

    try {
      setLoadingMessage("Changing Subdomain Target Address");
      setGlobalLoading(true);

      const editTarget = await registrarContract.changeSubdomainTarget(
        parsedText,
        inputTarget
      );

      await editTarget.wait();
      getSubdomainInfo();

      setGlobalLoading(false);
      successToast("Target Address Successfully Changed!");
    } catch (error) {
      setGlobalLoading(false);
      errorToast("Couldn't Change Subdomain Address");
    }
  };

  const deleteSubdomain = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 109) {
      switchNetwork?.(109);
    }

    const registrarContract = new ethers.Contract(
      registrarAdd,
      registrarAbi,
      signer
    );

    try {
      setGlobalLoading(true);
      setLoadingMessage("Confirming Subdomain Deletion");

      const deleteSubDom = await registrarContract.deleteSubDomain(
        parsedSubdomain
      );

      await deleteSubDom.wait();

      setGlobalLoading(false);
      successToast("Subdomain Successfully Deleted!");

      setVisibility(false);
      refresher();
    } catch (error) {
      console.log(error);
      setGlobalLoading(false);
      errorToast("Couldn't Delete Subdomain");
    }
  };

  return (
    <>
      {visibility && (
        <>
          {globalLoading && <Loading />}

          <section
            onClick={() => setVisibility(false)}
            className="fixed w-full bottom-0 right-0 left-0 top-0 bg-[#000000e0] z-50 flex items-center justify-center"
          ></section>

          <div className="bg-white min-h-[400px] w-[360px] fixed -translate-y-[50%] z-[51] -translate-x-[50%] left-[50%] top-[50%] py-10 flex flex-col justify-center items-center rounded-sm">
            {localLoading ? (
              <LocalLoading text={"none"} />
            ) : (
              <>
                <h2 className="text-xl mb-4 font-bold">
                  Edit Info For{" "}
                  <span className="bg-[#0999] p-[1px] px-2 rounded-md">
                    {subdomain}
                  </span>
                </h2>

                <span className="w-full max-w-[350px] px-2 font-semibold mt-4 italic">
                  Description
                </span>
                <h2 className="text-lg overflow-y-auto max-w-[350px] h-28 w-full text-center bg-[#a3a8b0] rounded-sm p-1 px-2 mb-1 font-bold">
                  {description}
                </h2>

                {/* Target Address */}
                <CopyToClipboard text={target}>
                  <h2
                    onClick={handleCopy}
                    className="cursor-pointer text-lg max-w-[350px] w-full text-center bg-gray-300 rounded-sm p-1 px-2 mb-4 font-bold"
                  >
                    {copied ? (
                      <span>Address Copied!</span>
                    ) : (
                      <div className="flex justify-center items-center gap-2">
                        {longAddressCrop(target)}{" "}
                        <img
                          src={copy}
                          alt="copy"
                          className="w-5"
                        />
                      </div>
                    )}
                  </h2>
                </CopyToClipboard>

                <div className="w-full flex flex-col items-center gap-1">
                  {/* Description */}
                  <div className="flex mt-1 gap-1 justify-center items-center">
                    <input
                      spellCheck={false}
                      value={inputDesc}
                      onChange={handleDescChange}
                      placeholder="description"
                      type="text"
                      className="bg-[#474f5e] outline-none shadow-lg text-center rounded-sm p-2 text-white"
                    />

                    <button
                      onClick={changeDescription}
                      className="p-2 bg-[#1d283b] shadow-lg rounded-sm text-white"
                    >
                      Change
                    </button>
                  </div>

                  {/* Target Address */}
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      spellCheck={false}
                      value={inputTarget}
                      onChange={handleTargetChange}
                      placeholder="target address"
                      type="text"
                      className="bg-[#474f5e] outline-none shadow-lg text-center rounded-sm p-2 text-white"
                    />

                    <button
                      onClick={changeTarget}
                      className="p-2 bg-[#1d283b] shadow-lg rounded-sm text-white"
                    >
                      Change
                    </button>
                  </div>

                  {invalidAddress && inputTarget !== "" && (
                    <div className="max-w-[300px] border-l-[4px] bg-[#DADBDB] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center mt-1 font-bold w-full py-2 text-sm text-center">
                      <img
                        src={warning}
                        alt=""
                        className="w-8"
                      />
                      This is not a valid address!
                    </div>
                  )}

                  {hasSubdom && (
                    <div className="max-w-[300px] border-l-[4px] bg-[#DADBDB] text-[#DD5448] border-[#DD5448] flex items-center gap-2 justify-center mt-1 font-bold w-full py-2 text-sm text-center">
                      <img
                        src={warning}
                        alt=""
                        className="w-8"
                      />
                      Address already has a subdomain!{" "}
                    </div>
                  )}
                </div>

                {/* buttons */}
                <div className="flex gap-1 font-bold mt-4">
                  <button
                    onClick={() => setVisibility(false)}
                    className="p-2 bg-[#656565] rounded-sm text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={deleteSubdomain}
                    className="p-2 bg-[#ff4545] rounded-sm text-black"
                  >
                    Delete Subdomain
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div>
        <li
          onClick={openInfoCard}
          className="bg-[#191d26] hover:border-[#3c4e60] text-white w-40 md:w-60 border-2 border-[#212c37] h-20 px-2 gap-2 flex flex-col justify-center items-center rounded-sm cursor-pointer text-center"
        >
          <h2 className="font-bold leading-[20px] text-lg md:text-xl">
            {subdomain}
          </h2>
          <h2 className="font-bold leading-[10px] md:leading-[18px] text-gray-400 text-sm md:text-md">
            {parent}.inu
          </h2>
        </li>
      </div>
    </>
  );
};

export default UserSubDomainlistItem;
