import styles from "./styles.module.css";

const CardLoading = ({ text }) => {
  return (
    <div className="flex flex-col items-center">
      <div className={styles.loading}></div>
    </div>
  );
};

export default CardLoading;
