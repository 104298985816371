import { useContext } from "react";
import styles from "./styles.module.css";
import { AppContext } from "../../../context/appContext";

const Loading = ({ text }) => {
  const { loadingMessage } = useContext(AppContext);
  return (
    <div className="bg-[#000000bb] w-full h-screen flex justify-center items-center fixed top-0 bottom-0 right-0 left-0 z-[52]">
      <div className="flex flex-col items-center">
        <div className={styles.loading}></div>
        <h2 className="text-xl md:text-2xl font-bold text-[#86e3ff] mt-10">
          {loadingMessage}
        </h2>
      </div>
    </div>
  );
};

export default Loading;
