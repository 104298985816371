import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./Home";
import User from "./user";
import Domain from "./Domain";
import { ethers } from "ethers";
import registryAbi from "./contracts/registry_abi.json";
import Loading from "./components/UI/LoadingAnimation/Loading";
import { AppContext } from "./context/appContext";

function App() {
  const [domain, setDomain] = useState();
  const { isLoading } = useContext(AppContext);

  return (
    <React.Fragment>
      <BrowserRouter>
        {isLoading && <Loading />}
        <Header />

        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={<Home setter={setDomain} />}
          />
          <Route
            path="/:id"
            element={
              <Domain
                setter={setDomain}
                domain={domain}
              />
            }
          />

          <Route
            path="/user"
            element={<User />}
          />
        </Routes>

        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
